type Props = {
    week: React.ReactNode,
    from: React.ReactNode;
    to: React.ReactNode;
    full?: boolean;
}

export default function CampsDate({ week, from, to, full }: Props) {
    return (
        <>
            <div className="flex flex-col">
                <div className={full ? " line-through" : ""}>{week}</div>
                {full && <div className=" text-xs italic">Complet</div>}
            </div>
            <div className={full ? " line-through" : ""}>{from}</div>
            <div className={full ? " line-through" : ""}>{to}</div>
        </>
    );
}

