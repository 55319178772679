import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';
import ErrorPage from './error-page';
import Chevalex from './components/Chevalex';
import Course from './components/Course';
import Logo from './components/logo';
import Pedagogical from './components/Pedagogical';
import Contacts from './components/Contacts';
import Pension from './components/Pension';
import Camps from './components/Camps';
import Horses from './components/Horses';
import Root from './root';
import Dance from './components/Dance';
import Competition from './components/Competition';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        element: <Chevalex />,
        index: true
      },
      {
        path: "/cours",
        element: <Course />
      },
      {
        path: "/pedagogique",
        element: <Pedagogical />
      },
      {
        path: "/contacts",
        element: <Contacts />
      },
      {
        path: "/demi-pension",
        element: <Pension />
      },
      {
        path: "/camps-de-jour",
        element: <Camps />
      },
      {
        path: "/pedagogique",
        element: <Pedagogical />
      },
      {
        path: "/chevaux",
        element: <Horses />
      },
      {
        path: "/ar",
        element: <Horses />
      },
      {
        path: "/dance",
        element: <Dance />
      },
      {
        path: "/competition",
        element: <Competition />
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    {/* <Logo /> */}
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
