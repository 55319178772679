import { MdArrowCircleDown } from "react-icons/md";
import { useMediaQuery } from "react-responsive";
import {
  Link,
  Element,
  Events,
  animateScroll,
  scrollSpy,
  scroller
} from "react-scroll";
import CampsDate from "./CampsDate";
import CampsImage from "./CampsImage";
import CampsItem from "./CampsItem";
import CommonButton from "./CommonButton";
import CourseThumbnail from "./CourseThumbnail";
import { images } from "./Images";
import PageHeader from "./PageHeader";
import PensionBox from "./PensionBox";
import Underline from "./Underline";
import UnderlineSmall from "./UnderlineSmall";

export default function Pension() {

  const lg = useMediaQuery({ query: '(max-width: 1024px)' });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

  return <>
    <div className="flex flex-col">
      <PageHeader
        title={<>Demi-Pension et Quart de Pension</>}
        subtitle={<>Plusieurs de nos chevaux sont disponibles en demi-pension ou quart de pension.</>}
      />


      <Element name="section-01" className="w-screen min-h-screen bg-stone-700 text-stone-300 relative snap-start p-4 flex flex-row">
        <div className="w-full portrait:w-4"></div>
        <div className="flex flex-col grow mt-2 lg:mt-8 landscape:max-w-6xl">
          <div className="text-3xl lg:text-6xl font-extrabold">Demi-Pension, Quart de Pension</div>
          <div className="text-md lg:text-2xl font-light pt-2">Sans devenir propriétaire, vous pouvez avoir accès à un cheval, aux installations et aux équipements plusieurs fois par semaine.</div>
          <Underline />
          <div className="flex flex-col grow r h-full mb-20">
            <p className="text-lg font-light">
              Vous cherchez à vivre une expérience équestre unique et personnalisée ?
              Optez pour la demi-pension ou quart de pension et profitez des avantages d'avoir votre propre cheval sans les coûts élevés qui y sont associés.
              Plusieurs de nos chevaux sont disponibles pour la demi-pension ou le quart de pension,
              ce qui vous permet de partager les frais tout en ayant un accès régulier à l'équitation et aux soins équestres.
              Contactez-nous pour en savoir plus sur nos options de pension.
            </p>
            <div className="flex flex-col grow r h-full mb-20">
              <div className="flex flex-row items-start justify-start mt-16">
                <div className="mr-8">
                  <PensionBox
                    title={<>Demi-Pension</>}
                    price={<>205$ par mois</>}
                    access={<>3 montes par semaine</>}
                  /></div>
                <PensionBox
                  title={<>Quart-Pension</>}
                  price={<>150$ par mois</>}
                  access={<>2 montes par semaine</>}
                />
              </div>
              <h4 className="mt-12 text-2xl  decoration-amber-600">Ce qui est inclus</h4>
              <UnderlineSmall/>
              <div className="flex flex-col items-start">
                <CampsItem text={<>Équipement équestre prêté, si nécessaire.</>} />
                <CampsItem text={<>Un casier partagé est fourni pour votre équipement.</>} />
                <CampsItem text={<>Accès à toutes les installations:</>} />
                <CampsItem text={<span className="pl-10">• Manège intérieur</span>} />
                <CampsItem text={<span className="pl-10">• Manège extérieur</span>} />
                <CampsItem text={<span className="pl-10">• Rond de longe ("Round Pen")</span>} />
                <CampsItem text={<span className="pl-10">• Sentiers pour randonnée</span>} />
                <CampsItem text={<span className="pl-10">• Salon et cuisine</span>} />
              </div>
              <h4 className="mt-12 text-2xl  decoration-amber-600">À Savoir</h4>
              <UnderlineSmall/>
              <div className="flex flex-col items-start">
                <CampsItem text={<>Le montant est payable le 1er du mois.</>} />
                <CampsItem text={<>Casque obligatoire pour les mineurs.</>} />
                <CampsItem text={<>Le couple formé par le cheval et le cavalier doit être validé lors d'un essai.</>} />
                <CampsItem text={<>Au minimum un cours par mois est exigé pour le couple cheval-cavalier afin de maintenir un bon entraînement de la part des deux parties.</>} />
                <CampsItem text={<>Il est interdit de couper la crinière et la queue du cheval.</>} />
                <CampsItem text={<>Le prêt du cheval est interdit.</>} />
              </div>
              <p className="mt-12 text-md italic ">* Pour plus d'informations, n'hésitez pas à vous informer sur place ou à nous contacter.</p>
            </div>
          </div>
        </div>
        <div className="w-full portrait:w-4"></div>
      </Element>

    </div>
  </>
}
