import {
  Link,
  Element
} from "react-scroll";
import { images } from "./Images";
import { useMediaQuery } from "react-responsive";
import CommonButton from "./CommonButton";
import { MdArrowCircleDown } from 'react-icons/md';
import { useEffect } from "react";
import CampsItem from "./CampsItem";
import CampsDate from "./CampsDate";
import CampsImage from "./CampsImage";
import PageHeader from "./PageHeader";
import GetFacebookLink from "./FacebookLink";
import Underline from "./Underline";
import UnderlineSmall from "./UnderlineSmall";

export default function Pedagogical() {

  const lg = useMediaQuery({ query: '(max-width: 1024px)' });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

  useEffect(() => {
    const inViewport = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        entry.target.classList.toggle("is-inViewport", entry.isIntersecting);
      });
    };

    const observer = new IntersectionObserver(inViewport);
    const element = document.querySelectorAll('[data-inviewport]');
    element.forEach(e => observer.observe(e));
  }, []);

  return <>
    <div className="flex flex-col">
      <PageHeader
        title={<>Journées Pédagogiques</>}
        subtitle={<>Offertes toute l'année, des journées de rêve pour vos enfants en congé!</>}
      />
      <Element name="section-01" className="w-screen min-h-screen bg-stone-700 text-stone-300 relative snap-start p-4 flex flex-row">
        <div className="w-full portrait:w-4"></div>
        <div className="flex flex-col grow mt-2 lg:mt-8 landscape:max-w-6xl">
          <div className="text-3xl lg:text-6xl font-extrabold">Journées Pédagogiques</div>
          <div className="text-md lg:text-2xl font-light pt-2">Apprendre en s'amusant : des journées pédagogiques remplies d'activités équestres.</div>
          <Underline />

          <div className="flex flex-col grow r h-full mb-20">
            <p className="text-lg font-light">
              Nos journées pédagogiques à l'écurie offrent une expérience éducative unique pour les enfants.
              Encadrés par des moniteurs passionnés, les participants pourront découvrir le monde fascinant des chevaux, apprendre les rudiments de l'équitation
              et participer à des activités enrichissantes. Des journées remplies de découvertes et de souvenirs inoubliables à vivre à l'écurie !
            </p>
            <h4 className="mt-12 text-2xl  decoration-amber-600">Activités et Apprentissages</h4>
            <UnderlineSmall/>
            <div className="flex flex-col items-start">
              <CampsItem icon={<>🐴</>} text={<>Apprendre à s'occuper d'un cheval</>} />
              <CampsItem icon={<>🧹</>} text={<>Apprendre ce qu’est une régie d’écurie</>} />
              <CampsItem icon={<>🌾</>} text={<>Apprendre à donner le foin et la moulée</>} />
              <CampsItem icon={<>🐎</>} text={<>Apprendre à monter à cheval </>} />
              <CampsItem icon={<>🌳</>} text={<>Randonnées</>} />
              <CampsItem icon={<>🍂</>} text={<>Profiter de la campagne</>} />
            </div>
          </div>

        </div>
        <div className="w-full portrait:w-4"></div>
      </Element>
      <Element name="section-02" className="w-screen min-h-screen-more bg-stone-100 relative snap-start flex flex-row pb-24">
        <div className="flex flex-col mt-32 px-10">
          <div className="text-3xl lg:text-4xl font-extrabold">Dates Offertes</div>
          <div className="text-md lg:text-2xl font-light pt-2">Offertes pour toutes les journées pédagogiques du calendrier scolaire.</div>
          <div className="bg-amber-600 w-96 portrait:w-52 h-1 mt-5 mb-10"></div>
          <div className="flex flex-col text-blue-700 underline text-xl cursor-pointer">
            <div className="mb-2"><a href="https://www.csssh.gouv.qc.ca/wp-content/uploads/2022/12/Calendrier-2023-2024-FGJ-2.pdf" target="_blank" rel="noreferrer">Calendrier Scolaire 2023 - 2024</a></div>          </div>
          <p className="mt-20">Un rappel des journées pédagogiques est fait sur notre page <a href={GetFacebookLink()} target="_blank" rel="noreferrer" className="text-blue-700 underline">Facebook</a>. N'hésitez pas à nous suivre ou à nous contacter pour plus d'informations.
            <br />Les places disponibles sont limitées, réservez vos places le plus tôt possible.</p>
        </div>
      </Element>


    </div>
  </>
}
